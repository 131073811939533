import { Navigate, createBrowserRouter } from "react-router-dom";
import Generic from "../container/Generic";
import Voucher from "../container/Voucher";
import Layout from "../components/Layout";
import Terms from "../container/Terms";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <Generic /> },
      { path: "/:token", element: <Voucher /> },
      { path: "/:listId/terms", element: <Terms /> },
    ],
  },
  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
]);
