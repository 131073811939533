import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../api/Api";
import { LoadingContext } from "../context/LoadingContext";

function Terms() {
  const params = useParams();
  const navigate = useNavigate();
  const [terms, setTerms] = useState(null);
  const { setLoading } = useContext(LoadingContext);

  const getTerms = () => {
    setLoading(true);
    Api.request(`list/terms/${params?.listId}`, "GET", null)
      .then((res) => {
        if (res && res?.result) {
          setTerms(res?.data?.terms);
          setLoading(false);
        } else {
          navigate("/", {
            state: {
              message: res?.error,
            },
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getTerms();
  }, []);

  return (
    <>
      <div className="container">
        <div className="terms-content">
          <div className="terms">
            {terms && (
              <div>
                <h1 style={{ fontSize: "48px" }}>TERMS & CONDITIONS</h1>
                <hr style={{ opacity: "20%", marginBottom: "16px" }} />
                <div dangerouslySetInnerHTML={{ __html: terms }}></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Terms;
