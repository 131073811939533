import { useContext } from "react";
import { LoadingContext } from "../context/LoadingContext";
import { Outlet } from "react-router-dom";

export default function Layout() {
  const { loading } = useContext(LoadingContext);
  return (
    <>
      <div className={`loading-wrapper ${!loading && "done"}`}>
        <div className="loading-spinner" />
      </div>
      <Outlet />
    </>
  );
}
