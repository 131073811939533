import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import leftHand from "../assets/images/leftHand.png";
import Api from "../api/Api";
import { LoadingContext } from "../context/LoadingContext";
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Voucher() {
  const params = useParams();
  const query = useQuery();
  const navigate = useNavigate();
  const [reward, setReward] = useState("");
  const [branding, setBranding] = useState(null);
  const { setLoading } = useContext(LoadingContext);

  const getRewardAndBranding = () => {
    setLoading(true);
    const phoneNumber = query.get("user");
    Api.request(
      `voucher/claim/${params.token}?user=${phoneNumber}`,
      "POST",
      null
    ).then((res) => {
      if (res && res?.result) {
        setReward(res?.data?.voucher);
        setBranding(res?.data?.branding);
        setLoading(false);
      } else {
        navigate("/", {
          state: {
            message: res?.error,
          },
        });
      }
    });
  };

  const redirect = () => {
    if (branding?.tcButtonLink) {
      window.open(branding?.tcButtonLink, "_blank");
    }
  };

  useEffect(() => {
    if (!query?.get("user")) {
      navigate("/", {
        state: {
          message: {
            message : "User not found"
          },
        },
      });
    } else {
      getRewardAndBranding();
    }
  }, [query]);

  return (
    <>
      <div className="container">
        <header className="header">
          {branding?.header && (
            <img
              src={branding?.header}
              alt="Uploaded"
              style={{ width: "100%" }}
            />
          )}
          {branding?.logo && (
            <img
              src={branding?.logo}
              alt="Uploaded"
              className="logo"
              style={{ paddingTop: `${!branding?.header ? "24px" : ""}` }}
            />
          )}
          {branding?.title && (
            <span
              className="subtitle"
              style={{
                paddingTop: `${
                  !branding?.header && !branding?.logo ? "24px" : ""
                }`,
              }}
            >
              {branding?.title}
            </span>
          )}
          <div>
            <div className="voucherBox">
              {branding?.voucherText && <span>{branding?.voucherText}</span>}
              <p>{reward}</p>
            </div>
          </div>
        </header>
        <div></div>
        <div className="content">
          {branding?.smsText && (
            <div className="redeemBox">
              <img
                src={leftHand}
                alt="Holding pint"
                style={{ borderBottomLeftRadius: "12px" }}
              />
              <p>{branding?.smsText}</p>
              <img src={leftHand} style={{ opacity: 0 }} alt="Holding pint" />
            </div>
          )}
          <hr className="divider" />
          <div className="redeemInstructions">
            {branding?.redeemTitle && (
              <h1 className="title">{branding?.redeemTitle}</h1>
            )}
            {branding?.redeemText && (
              <span
                dangerouslySetInnerHTML={{ __html: branding?.redeemText }}
              ></span>
            )}
          </div>
          <hr className="divider" />
          {branding?.tcButtonText && (
            <button onClick={redirect}>{branding?.tcButtonText}</button>
          )}
        </div>
      </div>
    </>
  );
}

export default Voucher;
