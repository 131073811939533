const API_URL = process.env.REACT_APP_API_URL;

export default class Api {
  static getHeaders() {
    return {
      "Content-Type": "application/json",
    };
  }

  static async request(url, method, payload) {
    const response = await fetch(`${API_URL}/api/${url}`, {
      method: method,
      body: payload ? JSON.stringify(payload) : null,
      headers: this.getHeaders(),
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Something went wrong");
    }
    return data;
  }
}
