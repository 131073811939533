import React, { useContext, useEffect } from "react";
import sorry from "../assets/images/sorry.png";
import { useLocation } from "react-router-dom";
import { LoadingContext } from "../context/LoadingContext";

function Generic() {
  const location = useLocation();
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
{location?.state?.message?.type === 1003 ? (   <div className="container">
      <div
        className="content"
        style={{
          textAlign: "left",
          paddingLeft: "0px",
        }}
      >
        <h1 className="generic-title">SORRY</h1>
        <p className="generic-description">
          {/* {location?.state?.message || "Bad request"} */}
          You have already entered this campaign.
        </p>
        <img src={sorry} className="generic-image" alt="Sorry " />
      </div>
    </div>): ( <div className="container">
      <div
        className="content"
        style={{
          textAlign: "left",
          paddingLeft: "0px",
        }}
      >
        <h1 className="generic-title">SORRY</h1>
        <p className="generic-description">
          {location?.state?.message?.message || "Bad request"}
        </p>
        <img src={sorry} className="generic-image" alt="Sorry " />
      </div>
    </div>)}
    </>
  );
}

export default Generic;
